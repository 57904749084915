import moment from "moment-timezone";
import { computed } from "vue";
import { getItem, setItem } from "@/store/persistantStorage";

export const ACCOUNT_MENU_ITEMS = [
    {
        routeName: "companyAccount",
        icon: "bank",
        label: "Company account",
        onlyMainUser: true,
        routeParams: { publisher: true },
    },
    {
        routeName: "personalInfo",
        icon: "account-circle",
        label: "Personal Info",
        onlyMainUser: false,
    },
    {
        routeName: "paymentMethod",
        icon: "credit-card-outline",
        label: "Payment Method",
        onlyMainUser: true,
        routeParams: { publisher: true },
    },
    {
        routeName: "userList",
        icon: "account-multiple",
        label: "User List",
        onlyMainUser: true,
        routeParams: { publisher: true },
    },
    {
        routeName: "changePassword",
        icon: "lock-outline",
        label: "Change Password",
        onlyMainUser: false,
    },
    {
        icon: "logout-variant",
        label: "Sign out",
        id: "logout",
        onlyMainUser: false,
    },
];

export const MAIN_MENU_ITEMS = [
    {
        routeName: "dashboard",
        icon: "view-dashboard-variant-outline",
        label: "Dashboard",
        routeParams: { publisher: true, webs: false },
    },
    // {
    //     routeName: "payouts",
    //     icon: "cash-multiple",
    //     label: "Payouts",
    //     superAdmin: true,
    // },
    // {
    //     routeName: "payoutsManagement",
    //     icon: "cash-multiple",
    //     label: "Payouts management",
    //     superAdmin: true,
    // },
    // {
    //     routeName: "approving",
    //     icon: "cash-multiple",
    //     label: "Approving",
    //     superAdmin: true,
    // },
    {
        routeName: "publishers",
        icon: "cash-multiple",
        label: "Publishers",
        superAdmin: true,
    },
    {
        routeName: "fees",
        icon: "cash-multiple",
        label: "Fees",
        superAdmin: true,
    },
    {
        routeName: "websites",
        icon: "web",
        label: "Websites",
        routeParams: { publisher: true },
    },
    {
        routeName: "install",
        icon: "tools",
        label: "Install",
        routeParams: { publisher: true },
        name: false,
    },
    {
        routeName: "events",
        icon: "cash-multiple",
        label: "Events",
        superAdmin: true,
    },
];

export const UNLOGGED_MENU_ITEMS = [
    {
        routeName: "login",
        label: "SIGN IN",
    },
];

export const IntervalEnum = Object.freeze({
    Dates: 0,
    Periods: 1,
});

export const PeriodsEnum = Object.freeze({
    Yesterday: "Yesterday",
    Last7Days: "Last 7 days",
    Last14Days: "Last 14 days",
    Last30Days: "Last 30 days",
    Last3Months: "Last 3 months",
    ThisMonth: "This month",
    PreviousMonth: "Previous month",
    ThisYear: "This year",
});
export const Periods = Object.freeze([
    { type: PeriodsEnum.Yesterday, name: "Yesterday", type_id: "yesterday" },
    { type: PeriodsEnum.Last7Days, name: "Last 7 days", type_id: "last_7_days" },
    { type: PeriodsEnum.Last14Days, name: "Last 14 days", type_id: "last_14_days" },
    { type: PeriodsEnum.Last30Days, name: "Last 30 days", type_id: "last_30_days" },
    { type: PeriodsEnum.Last3Months, name: "Last 3 months", type_id: "last_3_months" },
    { type: PeriodsEnum.ThisMonth, name: "This month", type_id: "this_month" },
    { type: PeriodsEnum.PreviousMonth, name: "Previous month", type_id: "previous_month" },
    { type: PeriodsEnum.ThisYear, name: "This year", type_id: "this_year" },
]);

export function getDatesByPeriod(periodEnum, tz) {
    const end = moment().endOf("day").format();
    switch (periodEnum) {
        case PeriodsEnum.Last7Days:
            return {
                start: moment()
                    .subtract(6, "days")
                    .startOf("day")
                    .tz(tz || "Europe/Prague")
                    .format(),
                end,
            };
        case PeriodsEnum.Yesterday:
            return {
                start: moment().subtract(1, "days").startOf("day").format(),
                end: moment().subtract(1, "days").endOf("day").format(),
            };
        case PeriodsEnum.Last14Days:
            return { start: moment().subtract(13, "days").startOf("day").format(), end };
        case PeriodsEnum.Last30Days:
            return { start: moment().subtract(29, "days").startOf("day").format(), end };
        case PeriodsEnum.PreviousMonth:
            return {
                start: moment().subtract(1, "months").startOf("month").format(),
                end: moment().subtract(1, "months").endOf("month").format(),
            };
        case PeriodsEnum.Last3Months:
            return { start: moment().subtract(2, "months").startOf("month").format(), end };
        case PeriodsEnum.ThisMonth:
            return { start: moment().startOf("month").format(), end };
        case PeriodsEnum.ThisYear:
            return { start: moment().startOf("year").format(), end };
        default: {
            const { dateFrom, dateTo } = getLastSevenDays();
            return { start: dateFrom, end: dateTo };
        }
    }
}

export function getLastSevenDays() {
    const maxDay = new Date();
    const sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    return { dateFrom: sevenDaysAgo, dateTo: maxDay };
}

export function getDefaultPeriod() {
    return PeriodsEnum.Last30Days;
}

export function guessDefaultTimeZone() {
    return moment.tz.guess() || "UTC";
}

export function getTimeZones() {
    const timezoneNames = moment.tz.names();
    const firstTimezones = ["Etc/GMT"];
    timezoneNames.sort(function (x, y) {
        if (firstTimezones.indexOf(x) > -1) {
            return -1;
        } else if (firstTimezones.indexOf(y)) {
            return 1;
        } else {
            return 0;
        }
    });
    return timezoneNames;
}

export function isWebsiteMonetized({ monetize }) {
    return monetize === "Monetized";
}

export function isWebsiteMeasured(web) {
    return web.verified || isWebsiteMonetized(web);
}

export function getValidAabSiteIds(websites) {
    return websites.filter(isWebsiteMeasured).map(({ aab_site_id }) => aab_site_id);
}

export function getPeriodObject(periodEnum) {
    return Periods.find((period) => period.type === periodEnum);
}

export function prepareBodyForInterval(period, aabSiteIds, timeZone) {
    const tz = timeZone || "Europe/Prague";
    const intervalEnum = typeof period === "string" ? IntervalEnum.Periods : IntervalEnum.Dates;
    return {
        aab_sites_ids: aabSiteIds,
        interval_enum: intervalEnum,
        period: intervalEnum === IntervalEnum.Periods ? getPeriodObject(period).type_id : period,
        time_zone: tz,
        interval: "1d",
        metadata: {},
    };
}

export function useModelProxy(props, emit, modelName = "modelValue") {
    return computed({
        get: () => props[modelName],
        set: (value) => emit("update:" + modelName, value),
    });
}

export function usePropertyObjectModelProxy(key, props, emit, modelName = "modelValue") {
    return computed({
        get: () => props[modelName]?.[key],
        set: (value) => emit("update:" + modelName, { ...(props[modelName] || {}), [key]: value }),
    });
}

export const exchange_rate = 26.12;
export const default_currency = "EUR";
export const list_of_currencies = ["EUR", "CZK"];

export function getExchangeRateFromCzk(currency) {
    if (currency === "CZK") return 1;
    return exchange_rate;
}

export function getExchangeRateFromEur(currency) {
    if (currency === "CZK") return exchange_rate;
    return 1;
}

export function getCurrencySymbol(currency) {
    if (currency === "CZK") return "Kč";
    return "€";
}

export const locale_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export function convertCurrency(value) {
    return Math.round((value / exchange_rate) * 100) / 100;
}

export function numberFormat(value, maximumSignificantDigits = 3) {
    let temp = Intl.NumberFormat("en-US", {
        maximumSignificantDigits: maximumSignificantDigits,
    });

    if (value > 99999) {
        return temp.format(value / 1000000) + "M";
    }

    if (value > 999) {
        return temp.format(value / 1000) + "k";
    }

    return temp.format(value);
}

export function switchTheme(redirect = true) {
    const el = document.body;
    const theme = getItem("theme");
    const newTheme = theme === "light" ? "dark" : "light";

    el.classList.remove(theme);
    el.classList.add(newTheme);
    setItem("theme", newTheme);

    if (redirect) {
        const currentUrl = window.location.href;
        window.location.href = currentUrl;
    }
}

export function numberFormatWithSpace(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function numberFormatImpressions(value) {
    if (value >= 1000000) {
        return (value / 1000000).toFixed(1) + "M";
    } else if (value >= 10000) {
        return (value / 1000).toFixed(0) + "k";
    } else if (value >= 100) {
        return (value / 1000).toFixed(1) + "k";
    } else {
        return "";
    }
}

export function numberFormatRevenue(value, currencySymbol) {
    if (value >= 1000000) {
        return value.toFixed(0) + "" + currencySymbol;
    } else if (value >= 1000) {
        return value.toFixed(0) + " " + currencySymbol;
    } else if (value >= 10) {
        return value.toFixed(0) + " " + currencySymbol;
    } else if (value > 1 && currencySymbol == "€") {
        return value.toFixed(2) + " " + currencySymbol;
    } else {
        return "";
    }
}

export function dateFormatAsText(value) {
    const date = new Date(value);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options).replace(/,/g, "");
}
